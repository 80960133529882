module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mehtap Aydin","short_name":"MehtapA","start_url":"/","background_color":"#f1f1f1","theme_color":"#a2466c","display":"standalone","icon":"src/images/MLogo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"64cc5b488be71759c264cc8ef845b568"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
